export const allowOnlyNumbers = (e, maxlength = undefined) => {
  const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

  const key = e.key;
  const value = e.target.value;

  if (['Backspace', 'Delete', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(key)) {
    return;
  }
  if (
    !allowedKeys.includes(key) ||
    (maxlength && value.length > maxlength - 1)
  ) {
    e.preventDefault()
  }
}
export function isValidEmail(email) {
  // Regular expression for basic email validation
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailRegex.test(email);
}
export const allowOnlyAlphanumeric = (e, maxlength = undefined) => {
  const allowedKeys = /^[a-zA-Z0-9]$/.test(e.key);

  const value = e.target.value;

  if (['Backspace', 'Delete', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)) {
    return;
  }
  if (!allowedKeys || (maxlength && value.length > maxlength - 1)) {
    e.preventDefault();
  }
}

//For names 
export const allowOnlyAlphabetsWithSpaces = (e, maxlength = undefined) => {
  const allowedKeys = /^[a-zA-Z\s]$/.test(e.key);

  const value = e.target.value;

  if (['Backspace', 'Delete', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)) {
    return;
  }
  if (!allowedKeys || (maxlength && value.length > maxlength - 1)) {
    e.preventDefault();
  }
}

export const allowDecimalsOnly = (e, maxlength = undefined) => {
  const allowedKeys = /^[0-9.]$/.test(e.key);
  const value = e.target.value;

  if (['Backspace', 'Delete', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)) {
    return;
  }

  // Check if the input is a valid decimal number
  if (!allowedKeys || (maxlength && value.length > maxlength - 1) || (value.includes('.') && e.key === '.')) {
    e.preventDefault();
  }
}

export const getLoggedInUser = () => {
  return JSON.parse(localStorage.getItem("lici_user"))
}

export const handlePhonenumberkeyDown = (e, phoneNumber) => {
  const prefix = phoneNumber.slice(0, 3);

  let maxLength;
  switch (prefix) {
    case "971":
      maxLength = 12; // Adjust for UAE
      break;
    case "973":
      maxLength = 11; // Adjust for Bahrain
      break;
    case "1":
      maxLength = 10; // Adjust for US
      break;
    case "91":
      maxLength = 12; // Adjust for India
      break;
    default:
      maxLength = 12; // Default max length
      break;
  }


  if (phoneNumber.length >= maxLength && e.key !== "Backspace") {
    e.preventDefault();
  }

  // Your existing onKeyDown logic
  allowOnlyNumbers(e);
};
