import { useState } from "react";
import PageLoader from "../../components/PageLoader";
import { useEffect } from "react";
import ProposalService from "../../services/proposal";
import { useParams } from "react-router-dom";
import { ReactComponent as Download } from "../../static/icons/download-icon.svg";
import { saveAs } from "file-saver"
import config from "../../services/config";
import { messageTypes } from "../../constants";
import moment from "moment";
import Breadcrumbs from '../../components/Breadcrumbs'

const ViewProposal = ({ showSnackbar }) => {

  const [is_loading, set_is_loading] = useState(true);
  const [data, set_data] = useState({});
  const [uploaded_documents, set_uploaded_documents] = useState([]);
  const [approved_documents, set_approved_documents] = useState([]);
  const [comments, set_comments] = useState("");
  const [is_sending_resubmit_request, set_is_sending_resubmit_request] = useState(false);
  const [is_submitting_to_lic, set_is_submitting_to_lic] = useState(false);
  const [proposal_id, set_proposal_id] = useState("");

  const params = useParams();
  const encrypted_id = params.id;

  useEffect(() => {
    getData(true);
    // eslint-disable-next-line
  }, [])

  const getData = (first_load = false) => {
    ProposalService.getProposal(encrypted_id).then((res) => {
      const res_data = res.data.data;
      set_data(res_data);
      set_uploaded_documents(res_data.uploaded_documents);
      set_proposal_id(res_data.id)

      const admin_actions = res_data.admin_actions;

      if (admin_actions && admin_actions.length) {
        const last_action = admin_actions[0]
        set_approved_documents(last_action.approved_documents || [])
      }

      if (first_load && res_data.have_notification_for_admin) {      
        //ProposalService.update(proposal_id, { have_notification_for_admin: false });       
        ProposalService.update(res_data.id, { have_notification_for_admin: false });       
      }

    }).finally(() => {
      set_is_loading(false);
    })
  }

  const downloadDocument = (uploaded_document) => {
    const filename = uploaded_document.filename;
    const fileUrl = `${config.baseUrl}/uploads/${uploaded_document.filename}`;
    const fileExtension = filename.substring(filename.lastIndexOf("."));
    const saveFileName = `${proposal_id}-${uploaded_document.title}${fileExtension}`;    
    saveAs(fileUrl, saveFileName);
  }

  const handleApproveDocument = (uploaded_document) => {
    let _approved_documents = [...approved_documents];
    const document_title = uploaded_document.title;
    const title_index = _approved_documents.indexOf(document_title);

    if (title_index === -1) {
      _approved_documents.push(document_title);
    } else {
      _approved_documents.splice(title_index, 1)
    }

    set_approved_documents(_approved_documents);
  }

  const handleResubmitDocuments = () => {
    if (comments.trim() === "") {
      showSnackbar("Please enter comments", messageTypes.WARNING)
    } else {
      let documents_to_reupload = [];

      uploaded_documents.forEach((uploaded_document) => {
        const document_title = uploaded_document.title;
        if (!approved_documents.includes(document_title)) {
          documents_to_reupload.push(document_title)
        }
      });

      set_is_sending_resubmit_request(true);

      const resubmit_data = {
        comments: comments,
        documents_to_reupload: documents_to_reupload,
        approved_documents: approved_documents
      }

      ProposalService.resubmitDocuments(proposal_id, resubmit_data).then(() => {
        showSnackbar("Request sent successfully !", messageTypes.SUCCESS);
        set_comments("");
        getData();
      }).finally(() => {
        set_is_sending_resubmit_request(false)
      })
    }
  }

  const handleSubmitToLIC = () => {
    const submit_data = {
      comments: comments
    }

    set_is_submitting_to_lic(true)
    ProposalService.submitProposal(proposal_id, submit_data).then(() => {
      showSnackbar("Proposal submitted successfully !", messageTypes.SUCCESS);
      set_comments("");
      getData();
    }).finally(() => {
      set_is_submitting_to_lic(false)
    })
  }

  return (
    <div className="site-container">
      <PageLoader show={is_loading} />
      {
        !is_loading &&
        <>
          <Breadcrumbs
            links={[
              { title: "Home", to: "/" },
              { title: "View Proposal" },
              { title: proposal_id }
            ]}
          />
          <div className="view-proposals-container">
            <div>
              <div className="paper">
                Please tick (✔) to approve and leave blank / unchecked for reject / re-submission

                {
                  uploaded_documents.map((uploaded_document, ind) => {

                    const is_approved = approved_documents.includes(uploaded_document.title);

                    return (
                      <div
                        className="d-flex align-items-center justify-content-between documents-list-item"
                        key={`UPLOADED-DOCUMENT-${ind + 1}`}
                      >
                        <div className="title">
                          {
                            `${uploaded_document.title} ${uploaded_document.subtitle ? ` - ${uploaded_document.subtitle}` : ""}`
                          }
                        </div>

                        <div className="d-flex align-items-center" style={{ gap: "10px" }}>

                          <div>
                            <label className="custom-check-label d-flex align-items-center cursor-pointer" style={{ minWidth: "92px" }}>
                              <input
                                type="checkbox"
                                className="d-none"
                                name="premium_payment_done"
                                checked={is_approved}
                                onChange={() => {
                                  handleApproveDocument(uploaded_document)
                                }}
                              />
                              <span></span>
                              {
                                is_approved ? "Approved" : "Approve"
                              }
                            </label>
                          </div>
                          <button
                            className="download-btn d-flex align-items-center"
                            onClick={() => downloadDocument(uploaded_document)}
                          >
                            Download File <Download className="ms-2"></Download>
                          </button>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div>
              <div className="paper">
                Proposal No : {proposal_id}

                <div className="table-container">
                  <table className="table table-bordered mb-0">
                    <tbody>
                      <tr>
                        <th> Submitted By </th>
                        <td> {data.created_user.name} </td>
                      </tr>
                      <tr>
                        <th> Agency Type </th>
                        <td> To be added </td>
                      </tr>
                      <tr>
                        <th> Code </th>
                        <td> {data.created_user.code} </td>
                      </tr>
                      <tr>
                        <th> Location </th>
                        <td> {data.created_user.place_name} </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>

              <div className="paper">
                Comments

                <textarea
                  className="w-100"
                  rows={3}
                  value={comments}
                  onChange={(e) => set_comments(e.target.value)}
                ></textarea>

                <div className="d-flex justify-content-end">
                  <button
                    className="button bordered"
                    disabled={uploaded_documents.length === approved_documents.length || is_sending_resubmit_request}
                    onClick={handleResubmitDocuments}
                  >
                    {is_sending_resubmit_request ? "Sending . . ." : "Re-submit Documents"}
                  </button>

                  <button
                    className="button ms-3"
                    disabled={uploaded_documents.length !== approved_documents.length || is_submitting_to_lic}
                    onClick={handleSubmitToLIC}
                  >
                    {is_submitting_to_lic ? "Submitting . . ." : "Submit to LIC"}
                  </button>
                </div>
              </div>

              <div className="paper">
                <div className="paper-title">
                  History
                </div>

                <div className="table-container">
                  <table className="table table-bordered mb-0">
                    <thead>
                      <tr>
                        <th> Date </th>
                        <th> Action </th>
                        <th> Comments </th>
                        <th> Action By </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        data.admin_actions && data.admin_actions.map((action, ind) =>
                          <tr key={`ACTION-${ind + 1}`}>
                            <td> {moment(action.timestamp).format("DD/MM/YYYY hh:mm A")} </td>
                            <td> {action.title} </td>
                            <td> {action.comments} </td>
                            <td> {action.actioned_by_user.name} </td>
                          </tr>
                        )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default ViewProposal;