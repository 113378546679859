import { ReactComponent as Eye } from "../static/icons/eye-icon.svg";
import { ReactComponent as EyeCross } from "../static/icons/eye-cross-icon.svg";
import { useState } from "react";

const PasswordInput = ({
  onChange,
  value
}) => {

  const [showPassword, setShowPassword] = useState(false);

  return(
    <div className="d-flex password-input-container">
      <input
        type={showPassword ? "text" : "password"}
        className="flex-grow-1 password-input"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <div
        className="password-toggle d-flex align-items-center justify-content-center flex-shrink-0"
        onClick={() => setShowPassword(!showPassword)}
      >
        {
          showPassword ? <EyeCross /> : <Eye />
        }
      </div>
    </div>
  )
}

export default PasswordInput;