import { useState } from "react";
import validator from "validator"
import { messageTypes } from "../../constants";
import AuthService from "../../services/auth";
import { Link } from "react-router-dom";

const ForgotPassword = ({ showSnackbar }) => {

  const [email, setEmail] = useState("");
  const [is_sending_mail, set_sending_mail] = useState(false)

  const handleSendRequest = (e) => {
    e.preventDefault();
    set_sending_mail(true);
    AuthService.sendPasswordResetEmail(email).then(() => {
      showSnackbar("Password reset mail sent successfully !", messageTypes.SUCCESS)
    }).catch(() => {
      showSnackbar("User not found with provided email address", messageTypes.ERROR)
    }).finally(() => {
      set_sending_mail(false)
    })
  }

  const isDataFilled = () => {
    if (
      validator.isEmail(email)
    ) {
      return true;
    }

    return false;
  }

  return (
    <div className="min-vh-100 d-flex align-items-center justify-content-center">
      <form onSubmit={handleSendRequest} className="login-form">
        <div className="title text-left"> Forgot Password </div>
        <label>Email </label>
        <input
          type="text"
          className="w-100 mb-0"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        {/*<div className="d-flex justify-content-between align-items-center" style={{ marginTop: '60px' }}>
          <div style={{ whiteSpace: 'nowrap' }}>
             <Link to="/login"> Back To Login </Link>
          </div>
          <div>
            <button className="button w-80" disabled={!isDataFilled() || is_sending_mail}>
            {is_sending_mail ? "Sending . . ." : "Request Password Reset Email"}
            </button>
          </div>       
  </div>*/}


        <button className="w-100 button mt-4" disabled={!isDataFilled() || is_sending_mail}>
          {is_sending_mail ? "Sending . . ." : "Request Password Reset Email"}
        </button>
        <div className="d-flex justify-content-center mt-3">
          <Link to="/login"> Back To Login </Link>
        </div>
      </form>
    </div>
  )
}

export default ForgotPassword;