import axios from "axios";
import { initializeApp } from "firebase/app";
import {
  signInWithEmailAndPassword,
  getAuth,
  signOut,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  sendPasswordResetEmail
} from "firebase/auth";
import config from "./config";

const baseUrl = config.baseUrl;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};


// DEV config
// const firebaseConfig = {
//   apiKey: "AIzaSyDf6wmikdC3xKti4sNwPRxScin7-jRM3n4",
//   authDomain: "lic-international-test.firebaseapp.com",
//   projectId: "lic-international-test",
//   storageBucket: "lic-international-test.appspot.com",
//   messagingSenderId: "545533602952",
//   appId: "1:545533602952:web:e130f19142626c94a6c33d",
//   measurementId: "G-Y1178HR45S"
// };

// PROD config
// const firebaseConfig = {
//   apiKey: "AIzaSyC7vB4tXnM_259D-JMJ7RxFiys9H5PjPRo",
//   authDomain: "lic-internation-prod.firebaseapp.com",
//   projectId: "lic-internation-prod",
//   storageBucket: "lic-internation-prod.appspot.com",
//   messagingSenderId: "798636277328",
//   appId: "1:798636277328:web:fe101b46e0a2455c70db55",
//   measurementId: "G-17CYDB60VV"
// };

initializeApp(firebaseConfig);

const firebaseAuth = getAuth();

const auth = {};

auth.login = async (email, password) => {
  return new Promise((resolve, reject) => {
    signInWithEmailAndPassword(firebaseAuth, email, password).then((authRes) => {
      const userData = authRes.user;
      const {uid} = userData;
      auth.sendOtp(uid).then((tokenRes) => {
        if(tokenRes.data.success && tokenRes.data.user && ['admin'].includes(tokenRes.data.user.role)){
          localStorage.setItem("lici_user", JSON.stringify(tokenRes.data.user));
          localStorage.setItem("lici_otp_token", tokenRes.data.token);
          resolve(true);
        }else{
          resolve(false);
        }
      }).catch(() => {
        resolve(false);
      })
    }).catch(() => {
      auth.logout();
      resolve(false)
    })
  })
}

auth.sendOtp = async (uid) => {
  const url = `${baseUrl}/auth/send-otp`;
  const res = await axios({
    url,
    method : "POST",
    data : {
      uid,
      portal : 'admin'
    }
  });
  return res;
}

auth.updatePassword = async (oldPassword, newPassword) => {

  return new Promise((resolve, reject) => {
    const currentUser = getAuth().currentUser;
  
    const credentials = EmailAuthProvider.credential(
      currentUser.email,
      oldPassword
    )
  
    reauthenticateWithCredential(currentUser, credentials).then(() => {
      updatePassword(currentUser, newPassword).then(() => {
        resolve({ passwordUpdated : true });
      }).catch((e) => {
        reject(e);
      })
    }).catch((e) => {
      reject(e);
    })
  })
}

auth.logout = () => {
  signOut(firebaseAuth);
  localStorage.removeItem("lici_access_token");
  localStorage.removeItem("lici_user");
}

auth.sendPasswordResetEmail = async (email) => {
  return new Promise((resolve, reject) => {
    sendPasswordResetEmail(firebaseAuth, email).then((emailRes) => {
      resolve(true);
    }).catch((error) => {
      reject(error)
    })
  })
}

auth.verifyOtp = async (otp, uid) => {
  const url = `${baseUrl}/auth/verify-otp`;
  const res = await axios({
    url,
    method : "POST",
    data : {
      otp,
      otp_token : localStorage.getItem("lici_otp_token"),
      uid : uid,
      role : "admin"
    }
  });
  return res;
}

auth.tempLogin = async () => {
  const url = `${baseUrl}/auth/temp-login`;
  const res = await axios({
    url,
    method : "POST"
  });
  return res;
}

auth.autoLogin = async (id) => {
  const url = `${baseUrl}/auth/auto-login/${id}`;
  const res = await axios({
    url,
    method : "POST"
  });
  return res;
}

auth.checkToken = async () => {
  const url = `${baseUrl}/token-check`;
  const res = await axios({
    url
  });
  return res;
}

export default auth;