import axios from "axios";
import config from "./config";

const baseUrl = config.baseUrl;

const proposal = {};

proposal.create = async (data) => {
  const url = `${baseUrl}/proposal/create`;
  const res = axios({
    url,
    method: "POST",
    data: data
  });

  return res;
}

proposal.getProposals = async (page, limit, search) => {
  const url = `${baseUrl}/proposal/get-proposals`;

  let params = {};

  if (page !== undefined && limit !== undefined) {
    params = {
      page,
      limit
    }
  }

  if (search) {
    params.search = search;
  }

  const res = axios({
    url,
    params
  });
  return res;
}

proposal.getReportProposals = async (page, limit, search) => {
  const url = `${baseUrl}/proposal/get-report-proposals`;

  let data = {};

  if (page !== undefined && limit !== undefined) {
    data = {
      page,
      limit
    }
  }

  data.search = search;

  const res = axios({
    url,
    method: "POST",
    data
  });
  return res;
}

proposal.getProposal = async (id) => {
  const url = `${baseUrl}/proposal/get-proposal/${id}`;
  const res = axios({ url });
  return res;
}

proposal.getProposalTemp = async (id, token) => {
  const url = `${baseUrl}/proposal/get-proposal-temp/${id}`;
  const res = axios({
    url,
    headers: {
      "Authorization": token
    }
  });
  return res;
}

proposal.update = async (id, data) => {
  const url = `${baseUrl}/proposal/update/${id}`;
  const res = axios({
    url,
    method: "PUT",
    data
  });
  return res;
}

proposal.updateTemp = async (id, data, token) => {
  const url = `${baseUrl}/proposal/update-temp/${id}`;
  const res = axios({
    url,
    method: "PUT",
    headers: {
      "Authorization": token
    },
    data
  });
  return res;
}

proposal.uploadDocument = async (id, data) => {
  const url = `${baseUrl}/proposal/upload-document/${id}`;
  const res = axios({
    url,
    method: "POST",
    headers: {
      'Content-Type': "multipart/form-data"
    },
    data: data
  });
  return res;
}

proposal.uploadDocumentTemp = async (id, data, token) => {
  const url = `${baseUrl}/proposal/upload-document-temp/${id}`;
  const res = axios({
    url,
    method: "POST",
    headers: {
      'Content-Type': "multipart/form-data",
      "Authorization": token
    },
    data: data
  });
  return res;
}

proposal.generatePDF = async (id) => {
  const url = `${baseUrl}/proposal/generate-pdf/${id}`;
  const res = axios({
    url,
    method: "POST"
  });
  return res;
}

proposal.generatePDFTemp = async (id, token) => {
  const url = `${baseUrl}/proposal/generate-pdf-temp/${id}`;
  const res = axios({
    url,
    headers: {
      "Authorization": token
    },
    method: "POST"
  });
  return res;
}

proposal.submitProposal = async (id, data) => {
  const url = `${baseUrl}/proposal/submit-proposal/${id}`;
  const res = axios({
    url,
    method: "POST",
    data : data
  });
  return res;
}

proposal.updatePaymentStatus = async (id, data) => {
  const url = `${baseUrl}/proposal/update-payment-status/${id}`;
  const res = axios({
    url,
    method: "POST",
    data
  });
  return res;
}

proposal.sendToMLRO = async (id, data) => {
  const url = `${baseUrl}/proposal/send-to-mlro-approval/${id}`;
  const res = axios({
    url,
    method: "POST",
    data
  });
  return res;
}

proposal.updateMlroStatus = async (id, data, token) => {
  const url = `${baseUrl}/proposal/update-mlro-status/${id}`;
  const res = axios({
    url,
    method: "POST",
    headers: {
      "Authorization": token
    },
    data
  });
  return res;
}

proposal.sendUploadMail = async (id) => {
  const url = `${baseUrl}/proposal/send-upload-mail-to-customer/${id}`;
  const res = axios({
    url,
    method: "POST"
  });

  return res;
}

proposal.deleteDocument = async (id, data) => {
  const url = `${baseUrl}/proposal/delete-document/${id}`;
  const res = axios({
    url,
    method: "POST",
    data: data
  });

  return res;
}

proposal.deleteProposal = async (id) => {
  const url = `${baseUrl}/proposal/delete-proposal/${id}`;
  const res = axios({
    url,
    method: "DELETE"
  });

  return res;
}

proposal.resubmitDocuments = async (id, data) => {
  const url = `${baseUrl}/proposal/resubmit-documents/${id}`;
  const res = axios({
    url,
    method: "POST",
    data : data
  });

  return res;
}
export default proposal;