import { useState } from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import PasswordInput from "../../components/PasswordInput";
import { messageTypes } from "../../constants";
import AuthService from "../../services/auth";

const Settings = ({ showSnackbar }) => {

  const [oldPassword, setOldPassowrd] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSavingPassword, setIsSavingPassword] = useState(false);

  const handleSavePassword = (e) => {
    e.preventDefault();

    if(newPassword.length < 6){
      showSnackbar("New Password length must be atleat 6 characters", messageTypes.WARNING)
    }
    else if(newPassword !== confirmPassword){
      showSnackbar("New Password and Confirm Password should be same", messageTypes.WARNING)
    }
    else{
      setIsSavingPassword(true);
      AuthService.updatePassword(oldPassword, newPassword).then((res) => {
        showSnackbar("Password updated successfully !");
        setOldPassowrd("");
        setNewPassword("");
        setConfirmPassword("");
      }).catch((err) => {
        const code = err.code;

        if(code === "auth/wrong-password"){
          showSnackbar("Incorrect current password !", messageTypes.ERROR)
        }
      }).finally(() => {
        setIsSavingPassword(false)
      })
    }
  }
  return(
    <div className="site-container settings-container">

      <Breadcrumbs
        links = {[
          { title : "Home", to : "/" },
          { title : "Settings" }
        ]}
      />

      <div className="grid-row">
        <form className="paper" onSubmit={handleSavePassword}>
          <div className="paper-title">
            Change Password
          </div>

          <div className="input-container">
            <label> Old Password </label>

            <PasswordInput
              value={oldPassword}
              onChange={(value) => setOldPassowrd(value)}
            />
          </div>

          <div className="input-container mt-3">
            <label> New Password </label>
            <PasswordInput
              value={newPassword}
              onChange={(value) => setNewPassword(value)}
            />
          </div>

          <div className="input-container mt-3">
            <label> Confirm New Password </label>
            <PasswordInput
              value={confirmPassword}
              onChange={(value) => setConfirmPassword(value)}
            />
          </div>

          <div className="d-flex justify-content-end mt-3">
            <button
              className="button" 
              disabled={
                isSavingPassword ||
                oldPassword === "" ||
                newPassword === "" ||
                confirmPassword === ""
              }
            >
              {isSavingPassword ? "Saving . . ." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Settings;