import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import withSnackbar from "./components/Snackbar";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import Layout from "./components/Layout";
import ForgotPassword from "./pages/ForgotPassword";
import VerifyOTP from "./pages/VerifyOTP";
import Settings from "./pages/Settings";
import ViewProposal from "./pages/ViewProposal";

function RoutesConfig({ showSnackbar }) {

  return (
    <>
      <Router>
        <Routes>
          <Route element={<Layout showSnackbar={showSnackbar} />}>
            <Route
              path="/"
              element={<Home showSnackbar={showSnackbar} />}
            />
            <Route
              path="/settings"
              element={<Settings showSnackbar={showSnackbar} />}
            />
            <Route
              path="/view-proposal/:id"
              element={<ViewProposal showSnackbar={showSnackbar} />}
            />
          </Route>

          {/* Routes without header */}
          <Route
            path="/login"
            element={<Login showSnackbar={showSnackbar} />}
          />
          <Route
            path="/forgot-password"
            element={<ForgotPassword showSnackbar={showSnackbar} />}
          />

          <Route
            path="/verify-otp"
            element={<VerifyOTP showSnackbar={showSnackbar} />}
          />

        </Routes>
      </Router>
      <Footer></Footer>
    </>
  );
}

export default withSnackbar(RoutesConfig);
