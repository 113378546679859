import React from "react";
import AXSSLogo from "../static/images/axxs-portal-logo-h.png"

const Footer = () => {
  const imageStyle = {
    width: '140px',
    height: 'auto', // Maintain aspect ratio
  };

  return (
    <div className="text-center text-center p-4">
      {/*© 2023{" "}
      <a className="text-reset fw-bold" href="https://axss.me/">
        AXSS PORTAL
      </a>{" "}
      All rights reserved.*/}
       Powered by {' '}
      <a href="https://axss.me/">
        <img src={AXSSLogo} alt="AXSS PORTAL" style={imageStyle} />
      </a>

    </div>
  );
};

export default Footer;
