import { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as EyeIcon } from "../../static/icons/eye-icon.svg";
import ProposalService from "../../services/proposal";
import { useEffect } from "react";
import moment from "moment";
import Pagination from "../../components/Pagination";
import { ReactComponent as Envelope } from "../../static/icons/envelope-icon.svg";

const Home = ({ showSnackbar }) => {
  const [proposals, setProposals] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  // const [search, setSearch] = useState("");
  // const [searchValue, setSearchValue] = useState("");
  

  // const handleChangeSearch = (e) => {
  //   const value = e.target.value;
  //   if (timeout) {
  //     clearTimeout(timeout);
  //   }
  //   timeout = setTimeout(() => {
  //     setSearch(value);
  //   }, 2000);

  //   setSearchValue(value);
  // };

  const getProposals = () => {
    
    ProposalService.getProposals(page, 10).then((res) => {
      setProposals(res.data.data);
      setCount(res.data.total_records)
    }).catch((error) => {
      console.log(error)
    })
  }

  useEffect(() => {
    getProposals();
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    sessionStorage.clear();
  }, [])


  // const deleteProposal = () => {
  //   ProposalService.deleteProposal(delete_proposal_id).then(() => {
  //     showSnackbar("Proposal deleted successfully !", messageTypes.SUCCESS);
  //     handleCloseDeleteModal();
  //     getProposals();
  //   }).catch(() => {
  //     showSnackbar("Proposal not deleted !", messageTypes.SUCCESS);
  //   }).finally(() => {
  //     set_is_deleting_proposal(false)
  //   })
  // }

  // const handleCloseDeleteModal = () => {
  //   set_delete_proposal_id("");
  //   set_show_delete_modal(false);
  // }

  // const getStatusColorClass = (status) => {
  //   switch (status.toUpperCase()) {
  //     case 'DRAFT':
  //       return 'status-draft'; 
  //     case 'FORM GENERATED':
  //       return 'status-form-generated';
  //     case 'MLRO APPROVAL':
  //       return 'status-mlro'; 
  //     default:
  //       return 'status-default';
  //   }
  // }

  // const handleClearSearch = () => {
  //   setSearch(''); // Clear the search value
  //   setSearchValue(''); // Clear the controlled input value
  // };

  return (
    <div className="site-container">

      <div className="paper mt-4">
        <table className="table">
          <thead>
            <tr>
              <th> SNo. </th>
              <th> Reference No. </th>
              <th> Customer Name </th>
              <th> Plan </th>
              {/* <th> Status </th> */}
              <th>Agent Name</th>
              <th> Created Date </th>
              <th className="text-center"> View </th>
            </tr>
          </thead>
          <tbody className="text-uppercase">
            {
              proposals.map((proposal, index) => {
                const sno = index + 1;
                const proposal_id = proposal.id;
                const proposal_enc_id = proposal.enc_id;

                const form_type = proposal.selected_plan?.form_type;
                const customer_name = form_type==="PF02" ?
                 `${proposal.proposer_details_2.proposer_first_name} ${proposal.proposer_details_2.proposer_middle_name} ${proposal.proposer_details_2.proposer_last_name}`:
                 `${proposal.proposer_details.proposer_first_name} ${proposal.proposer_details.proposer_middle_name} ${proposal.proposer_details.proposer_last_name}`;
                
                 const agent_name = `${proposal.created_user.name}`

                return (
                  <tr key={proposal_id}>
                    <td> {sno} </td>
                    <td> {proposal_id} </td>
                    <td> {customer_name} </td>
                    <td> {proposal.selected_plan && proposal.selected_plan.title ? proposal.selected_plan.title : "-"} </td>
                    {/* <td> <span className={getStatusColorClass(proposal.status)}>{proposal.status} </span></td> */}
                    <td> {agent_name}</td>
                    <td> {moment(proposal.created_at).format("DD-MM-YYYY")} </td>
                    <td className="text-center position-relative">
                      <Link to={`/view-proposal/${proposal_enc_id}`}className="d-inline-block">
                        <EyeIcon/>
                      </Link>
                      {
                        proposal.have_notification_for_admin &&
                        <Envelope className="envelope-icon" />
                      }
                    </td>
                  </tr>
                );
              })
            }

          </tbody>
        </table>
        <Pagination
          page={page}
          count={count}
          handleChangePage={(pageNo) => setPage(pageNo)}
        />
      </div>

    </div>
  );
};

export default Home;
