import { useState } from "react";
import { messageTypes } from "../../constants";
import { useNavigate, Link } from "react-router-dom";
import AuthService from "../../services/auth";

const VerifyOTP = ({ showSnackbar }) => {
  const [otp, setOtp] = useState("");
  const [isChecking, setIsChecking] = useState(false);
  const [isResending, setIsResending] = useState(false)

  const navigate = useNavigate();
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsChecking(true);
    const uid = JSON.parse(localStorage.getItem("lici_user")).uid;
    AuthService.verifyOtp(otp, uid).then((verifyOtpRes) => {
      const verifyOtpData = verifyOtpRes.data;
      setIsChecking(false);

      if(verifyOtpData.success){
        localStorage.removeItem("lici_otp_token");
        localStorage.setItem("lici_access_token", verifyOtpData.token)
        navigate("/");
      }else{
        showSnackbar("Invalid or expired OTP", messageTypes.ERROR)  
      }
      
    }).catch((error) => {
      showSnackbar("Invalid or expired OTP", messageTypes.ERROR)
    })
  }

  const isDataFilled = () => {
    if (otp.length === 6) {
      return true;
    }
    return false;
  };

  const handleResendOTP = () => {
    const uid = JSON.parse(localStorage.getItem("lici_user")).uid;
    setIsResending(true)
    AuthService.sendOtp(uid).then((sendOTPRes) => {
      setIsResending(false);
      localStorage.setItem("lici_otp_token", sendOTPRes.data.token);
      showSnackbar("OTP sent successfully !", messageTypes.SUCCESS)
    }).catch(() => {
      showSnackbar("OTP not sent !", messageTypes.ERROR)
    })
  }

  return (
    <div className="flex-grow-1 d-flex align-items-center justify-content-center">
      <form onSubmit={handleSubmit} className="login-form">
        <div className="title text-left"> Verify OTP </div>
        <input
          type="text"
          className="w-100 mb-0"
          value={otp}
          maxLength={6}
          onChange={(e) => setOtp(e.target.value)}
        />


        <div className="d-flex justify-content-between align-items-center" style={{ marginTop: '60px' }}>
          <div style={{ whiteSpace: 'nowrap' }}>
            <Link onClick={handleResendOTP} disabled={isResending} > {isResending ? "Resending . . ." : "Resend OTP"}</Link>
          </div>
          <div>
            <button className="button"  disabled={!isDataFilled() || isChecking}>
               {isChecking ? "Verifying . . ." : "Verify"}
            </button>
          </div>       
        </div>
        
       {/* <div className="d-flex justify-content-end">
          <button
            type = "button"
            className="resend-otp-button mt-4"
            onClick={handleResendOTP}
            disabled = {isResending}
          >
            {isResending ? "Resending . . ." : "Resend OTP"}
          </button>
        </div>
        <button className="w-100 mt-4" disabled={!isDataFilled() || isChecking}>
          {isChecking ? "Verifying . . ." : "Verify"}
       </button> */}
      </form>
    </div>
  );
};

export default VerifyOTP;
