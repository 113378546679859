import { useEffect, useState } from "react";
import { ReactComponent as Home } from "../static/icons/home-outline.svg";
import { ReactComponent as Help } from "../static/icons/help-circle-outline.svg";
import { ReactComponent as ApplicationsFilled } from "../static/icons/applications-icon-filled.svg";
import { ReactComponent as Logout } from "../static/icons/log-out-outline.svg";
import { ReactComponent as Settings } from "../static/icons/settings-outline.svg";
import { ReactComponent as Reports } from "../static/icons/reports-icon-filled.svg";
import { ReactComponent as User } from "../static/icons/person-circle-outline.svg";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { messageTypes } from "../constants";
import AuthService from "../services/auth";
import LICLogo from "../static/images/new_logo_2.png"
import { getLoggedInUser } from "../helpers";

const Header = ({ showSnackbar }) => {
  const user = getLoggedInUser();
  const user_role = user && user.role ? user.role : "";

  const [showSidebar, setShowSidebar] = useState(false);
  const [is_token_checked, set_is_token_checked] = useState(false);

  const navigate = useNavigate()

  axios.interceptors.request.use(request => {
    const token = localStorage.getItem("lici_access_token");
    request.headers["Authorization"] = token;
    return request;
  })

  axios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    if (
      error &&
      error.response &&
      error.response.status === 401 &&
      !error.response.config.url.includes('/token-check')
    ) {
      if(is_token_checked){
        showSnackbar("Session expired ! Please login again", messageTypes.ERROR)
        setTimeout(() => {
          AuthService.logout();
          navigate("/login");
        }, 3500)
      }
    }
    console.log(error)
    return Promise.reject(error)
  })

  useEffect(() => {
    AuthService.checkToken().then(() => {
    }).catch(() => {
      AuthService.logout();
      navigate("/login");
    }).finally(() => {
      set_is_token_checked(true)
    })

    // eslint-disable-next-line
  }, [])

  return (
    <>
      <header className={`fixed-top d-flex align-items-center justify-content-between`}>
        <img src={LICLogo} alt="LIC International" />
        <div className="d-flex align-items-center">
          <div className="d-flex justify-content-end align-items-end">
            <p className="user-name"><User className="user-icon" />{getLoggedInUser() ? getLoggedInUser().name : ""}</p>
          </div>
          <div className="d-flex align-items-center justify-content-end nav-icons">
            <NavLink to="/" className="sidebar-link d-flex align-items-center">
              <Home className="icon home-icon" />
            </NavLink>
            {
              user_role === "super-agent" &&
              <NavLink to="/reports" className="sidebar-link d-flex align-items-center" >
              {/* onClick={AuthService.logout}> */}
                <Reports className="icon reports-icon" />
              </NavLink>
            }
            <NavLink to="/settings" className="sidebar-link d-flex align-items-center">
              <Settings className="icon settings-icon" />
            </NavLink>           
            <NavLink to="/help" className="sidebar-link d-flex align-items-center">
              <Help className="icon help-icon" />
            </NavLink>          
            <NavLink to="/login" className="sidebar-link d-flex align-items-center" onClick={AuthService.logout}>
              <Logout className="icon logout-icon" />
            </NavLink>
            
          </div>
          <div/>
        </div>
      </header>

      <div className={`sidebar-container ${showSidebar ? "show" : ""}`} onClick={() => setShowSidebar(false)}>
        <div className="sidebar">
          <NavLink to="/" className="sidebar-link d-flex align-items-center">
            <ApplicationsFilled className="icon" />
            Proposals
          </NavLink>
          <NavLink to="/settings" className="sidebar-link d-flex align-items-center">
            <Settings className="icon" />
            Settings
          </NavLink>
          <NavLink to="/login" className="sidebar-link d-flex align-items-center" >
            <Logout className="icon" />
            Logout
          </NavLink>
        </div>
      </div>

      <div className={`full-page-loader d-flex align-items-center justify-content-center ${is_token_checked ? "" : "show"}`}>
        <div className="loader-container d-flex align-items-center position-relative">
          <div className="loader-dot dot-1"></div>
          <div className="loader-dot dot-2"></div>
          <div className="loader-dot dot-3"></div>
        </div>
      </div>
    </>
  )
}

export default Header;