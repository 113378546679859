import { useState } from "react";
import validator from "validator";
import { messageTypes } from "../../constants";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../services/auth";
import PasswordInput from "../../components/PasswordInput";

const Login = ({ showSnackbar }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggingIn, setLogginIn] = useState(false);

  const navigate = useNavigate();
  //Password check
  const handleLogin = (e) => {
    e.preventDefault();
    setLogginIn(true);
    AuthService.login(email,password).then((loggedIn) => {
      setLogginIn(false);
      if(loggedIn){
        navigate("/verify-otp")
      }else{
        showSnackbar("Invalid username or password", messageTypes.ERROR)  
      }
    }).catch((error) => {
      showSnackbar("Invalid username or password", messageTypes.ERROR)
    })
  }

  const isDataFilled = () => {
    if (validator.isEmail(email) && password.length >= 6) {
      return true;
    }
    return false;
  };

  return (
    <div className="flex-grow-1 d-flex align-items-center justify-content-center">
      <form onSubmit={handleLogin} className="login-form">
        <div className="title text-left">Digi-Life Login </div>
        <label> Email </label>
        <input
          type="text"
          className="w-100"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label> Password </label>

        <PasswordInput
          value={password}
          onChange={(value) => setPassword(value)}
        />

        <div className="d-flex justify-content-between align-items-center" style={{ marginTop: '60px' }}>
          <div style={{ whiteSpace: 'nowrap' }}>
            <Link to="/forgot-password"> Forgot Password </Link> 
          </div>
        <div>
        <button  className="button"  disabled={!isDataFilled() || isLoggingIn}>
          {isLoggingIn ? "Signing in . . ." : "Sign in"}
        </button>       
        </div>
        {/*<div className="d-flex justify-content-end mt-4">*/}
          
        </div>
      </form>
    </div>
  );
};

export default Login;
