import { Outlet } from "react-router-dom";
import Header from "./Header";
const Layout = ({ showSnackbar }) => {

  return(
    <>
      <Header showSnackbar = {showSnackbar} />
      <Outlet />
      
    </>
  )
}

export default Layout;